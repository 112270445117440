.pinned-item-list-item {
  .pinned-item-handle {
    color: var(--fgColor-muted, var(--color-fg-muted));
  }

  .pinned-item-handle:hover {
    cursor: grab;
  }

  &.is-dragging,
  &.is-dragging .pinned-item-handle {
    cursor: grabbing;
  }

  &.is-dragging {
    background-color: var(--bgColor-accent-muted, var(--color-accent-subtle));
  }

  &.sortable-ghost {
    background-color: var(--bgColor-accent-muted, var(--color-accent-subtle));
    opacity: 0;
  }

  &.empty {
    border-style: dashed;
    border-width: var(--borderWidth-thin);
    align-items: center;
    justify-content: center;
  }
}

.pinned-item-list-item-content {
  display: flex;
  width: 100%; // For IE11
  flex-direction: column;
}

.pinned-item-desc {
  flex: 1 0 auto;
}

.pinned-item-meta {
  display: inline-block;

  + .pinned-item-meta {
    margin-left: var(--base-size-16);
  }
}
